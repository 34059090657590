import { gql, MutationHookOptions, useMutation, MutationFunctionOptions, FetchResult } from '@apollo/client';
import { RateSoftwareResponse } from '../types';

export const RATE_ITEM = gql`
  mutation rateSoftware($sourceId: String!, $rate: Float!, $type: String!, $token: String!) {
    rateSoftware(sourceId: $sourceId, rate: $rate, type: $type, token: $token) {
      message
    }
  }
`;

export const useRateItem = (
  options?: MutationHookOptions<RateSoftwareResponse, { sourceId: string; rate: number; type: string; token: string }>,
): [
  (
    options?: MutationFunctionOptions<
      RateSoftwareResponse,
      { sourceId: string; rate: number; type: string; token: string }
    >,
  ) => Promise<FetchResult<RateSoftwareResponse>>,
  string | undefined,
  boolean,
] => {
  const [rateItem, { data, loading }] = useMutation<RateSoftwareResponse>(RATE_ITEM, options);

  return [rateItem, data?.rateSoftware?.message, loading];
};
