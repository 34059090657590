import { useQuery, gql, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import type { GetLayoutDataByUrl, GetLayoutDataByUrlVariables } from '../__generated__/GetLayoutDataByUrl';

export const GET_LAYOUT_DATA_BY_URL = gql`
  query GetLayoutDataByUrl($url: String!) {
    layoutDataByUrl(url: $url) {
      data
    }
  }
`;

export const fetchLayoutDataByUrlQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  queryParam: GetLayoutDataByUrlVariables,
): Promise<ApolloQueryResult<GetLayoutDataByUrl>> => {
  return client.query<GetLayoutDataByUrl, GetLayoutDataByUrlVariables>({
    query: GET_LAYOUT_DATA_BY_URL,
    variables: queryParam,
  });
};

export const useLayoutDataByUrlQuery = (
  options?: QueryHookOptions<GetLayoutDataByUrl, GetLayoutDataByUrlVariables>,
) => {
  const { loading, error, data } = useQuery<GetLayoutDataByUrl, GetLayoutDataByUrlVariables>(
    GET_LAYOUT_DATA_BY_URL,
    options,
  );

  return { loading, error, layoutData: data?.layoutDataByUrl };
};
