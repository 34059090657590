import { ApolloClient, gql, NormalizedCacheObject, QueryHookOptions, useQuery } from '@apollo/client';
import { useState } from 'react';
import { GetItems } from '../__generated__/GetItems';
import { GetItem, GetItemVariables } from '../__generated__/GetItem';
import { GetListItems, GetListItems_items, GetListItemsVariables } from '../__generated__/GetListItems';
import { GetItemsForArchive, GetItemsForArchiveVariables } from '../__generated__/GetItemsForArchive';

export const GET_ITEMS_QUERY = gql`
  query GetItems {
    items {
      id
      title
      summary
      label
      url
      externalLink
      imageId
      layout
      republishedAt
      productReviewScore
      comments
      updatedAt
    }
  }
`;

export const GET_ITEMS_QUERY_FOR_ARCHIVE = gql`
  query GetItemsForArchive($date: String!, $from: Int, $queryType: QueryByType!) {
    archive(queryBy: { type: $queryType, value: $date }, from: $from, size: 100) {
      itemsByDate {
        id
        title
        summary
        label
        url
        externalLink
        imageId
        layout
        republishedAt
        updatedAt
        releasedAt
        comments
        approximateReleasedAt {
          quarter
          year
        }
        productReviewScore
        comments
      }
      daysList {
        date
        docCount
        groupByDay {
          docCount
          date
        }
      }
    }
  }
`;

export const GET_ITEM_QUERY = gql`
  query GetItem($id: ID!) {
    item(id: $id) {
      id
      title
      imageId
      url
      popularity
      userRating
      sponsors {
        title
        url
        order
      }
      userRatingNb
      ratingConclusion
      linkedWith {
        author {
          id
          title
          imageId
          url
          summary
          mainDataSheet {
            groups {
              shortSlug
              attributes {
                shortSlug
                topSpec
                iconSlug
                type
                url
                value
                name
              }
            }
          }
        }
      }
      mainDataSheet {
        groups {
          shortSlug
          attributes {
            shortSlug
            topSpec
            iconSlug
            type
            url
            value
            name
          }
        }
      }
      comments
      updatedAt
      republishedAt
      layout
    }
  }
`;

export const GET_LIST_ITEM_QUERY = gql`
  query GetListItems($id: String!, $from: Int, $size: Int) {
    items(queryBy: { type: item, value: $id }, from: $from, size: $size) {
      id
      title
      summary
      url
      imageId
      layout
      republishedAt
      comments
    }
  }
`;

export const fetchItems = (client: ApolloClient<NormalizedCacheObject>) => {
  return client.query<GetItems>({ query: GET_ITEMS_QUERY });
};

export const fetchItem = (client: ApolloClient<NormalizedCacheObject>, queryParam: GetItemVariables) => {
  return client.query<GetItem>({ query: GET_ITEM_QUERY, variables: queryParam });
};

export const fetchItemsByDate = (
  client: ApolloClient<NormalizedCacheObject>,
  queryParam: GetItemsForArchiveVariables,
) => {
  return client.query<GetItemsForArchive, GetItemsForArchiveVariables>({
    query: GET_ITEMS_QUERY_FOR_ARCHIVE,
    variables: queryParam,
  });
};

export const useItemsQueryByDate = (options?: QueryHookOptions<GetItemsForArchive, GetItemsForArchiveVariables>) => {
  const { loading, error, data } = useQuery<GetItemsForArchive>(GET_ITEMS_QUERY_FOR_ARCHIVE, options);
  const archive = data?.archive;

  return { loading, error, archive };
};

export const useItemQuery = (options?: QueryHookOptions<GetItem, GetItemVariables>) => {
  const { loading, error, data } = useQuery<GetItem, GetItemVariables>(GET_ITEM_QUERY, options);

  return { loading, error, item: data?.item };
};

export const useItemsQuery = (options?: QueryHookOptions<GetItems>) => {
  const { loading, error, data, fetchMore } = useQuery<GetItems>(GET_ITEMS_QUERY, options);
  const items = data?.items;

  return { loading, error, items, fetchMore };
};

export const useListItemsQuery = (options?: QueryHookOptions<GetListItems, GetListItemsVariables>) => {
  const { loading, error, data, fetchMore } = useQuery<GetListItems, GetListItemsVariables>(
    GET_LIST_ITEM_QUERY,
    options,
  );

  const [items, setItems] = useState<GetListItems_items[]>(data?.items || []);
  const [isLoading, setIsLoading] = useState<boolean>(loading);

  const fetchMoreData = ({ variables }: { variables: GetListItemsVariables }) => {
    setIsLoading(true);
    fetchMore({
      variables,
    })
      .then((response) => {
        setItems(items.concat(response.data?.items));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { loading: isLoading, error, items, fetchMoreData };
};
