import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache, InMemoryCacheConfig, NormalizedCacheObject } from '@apollo/client';
// import merge from 'lodash.merge';
import { APOLLO_STATE_PROP_NAME, ApolloState, PageProps } from './types';

let apolloClientInstance: ApolloClient<NormalizedCacheObject>;

type SDKConfig = {
  apiURL: string;
  siteName: string;
  cacheConfig?: InMemoryCacheConfig;
};
let sdkConfig: SDKConfig;
export function apiSdkInit(config: SDKConfig) {
  sdkConfig = config;
}

const EMPTY_CONFIG = JSON.stringify({});
function getFeatures() {
  if (typeof window === 'undefined' || !window.localStorage) {
    return EMPTY_CONFIG;
  }

  const features = window.localStorage.getItem('martyjs.features');
  if (!features) {
    return EMPTY_CONFIG;
  }

  return features;
}

function getUserToken(): string {
  if (typeof window === 'undefined' || !window.localStorage) {
    return null;
  }

  const userToken = window.localStorage.getItem('userToken');
  if (!userToken) {
    return null;
  }

  return userToken;
}

export const apiLink = (): HttpLink => {
  let userToken = null;
  if (getUserToken()) {
    userToken = getUserToken();
  }

  return new HttpLink({
    uri: sdkConfig.apiURL,
    headers: {
      X_SITE_NAME: sdkConfig.siteName,
      features: getFeatures(),
      userToken,
    },
  });
};

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (!sdkConfig) {
    throw new Error('SDK has not been configured. Please, be sure to call configure method before using this sdk.');
  }

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: apiLink(),
    cache: new InMemoryCache(sdkConfig.cacheConfig),
  });
}

export function getApiClient(initialState: NormalizedCacheObject = null) {
  const _apolloClient = apolloClientInstance ?? createApolloClient();

  // Get existing cache, loaded during client side data fetching
  const existingCache = _apolloClient.extract();
  const hasExistingCache = Object.keys(existingCache).length > 0;

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState && !hasExistingCache) {
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = { ...initialState, ...existingCache };

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClientInstance) {
    apolloClientInstance = _apolloClient;
  }

  return _apolloClient;
}

export function getServerSideCache(client?: ApolloClient<NormalizedCacheObject>): ApolloState {
  return {
    [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
  };
}

export function useApiSdk<T>(pageProps: PageProps<T>) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const apiClient = useMemo(() => getApiClient(state), [state]);

  return apiClient;
}

export { ApolloProvider as ApiProvider } from '@apollo/client';
