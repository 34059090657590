/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AttributeType {
  bool = "bool",
  enum = "enum",
  numeric = "numeric",
  unit = "unit",
}

export enum FilterPickerType {
  checkbox = "checkbox",
  linear_range = "linear_range",
  list_range = "list_range",
  select = "select",
}

export enum GenericItemType {
  item = "item",
  variant = "variant",
}

export enum PageType {
  item = "item",
  section = "section",
}

export enum QueryByType {
  archiveDay = "archiveDay",
  archiveMonth = "archiveMonth",
  archiveYear = "archiveYear",
  feed = "feed",
  item = "item",
  itemLinkAuthor = "itemLinkAuthor",
  keyword = "keyword",
  queryConfig = "queryConfig",
  section = "section",
}

export interface DataSheetFilter {
  attributeShortSlug: string;
  min?: number | null;
  max?: number | null;
  unlimited?: boolean | null;
  values?: string[] | null;
  bool?: boolean | null;
}

export interface TrackEventCustomVariable {
  id?: number | null;
  name?: string | null;
  value?: string | null;
}

export interface TrackViewDimension {
  id?: string | null;
  value?: string | null;
}

export interface ZoneAdServer {
  zoneSlug: string;
  keywords: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
