import { gql, useApolloClient } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { FetchResult } from '@apollo/client/link/core';
import { GetProgrammableComponent, GetProgrammableComponentVariables } from '../__generated__/GetProgrammableComponent';
import type { ProgrammableComponentImpression } from '../__generated__/programmableComponentImpression';
import type { ProgrammableComponentClickVariables } from '../__generated__/programmableComponentClick';

export const GET_PROGRAMMABLE_COMPONENT_QUERY_BY = gql`
  query GetProgrammableComponent(
    $userToken: String!
    $urlReferer: String!
    $gdpr: String!
    $userAgent: String!
    $zones: [ZoneAdServer]!
  ) {
    programmableComponent(
      userToken: $userToken
      urlReferer: $urlReferer
      gdpr: $gdpr
      userAgent: $userAgent
      zones: $zones
    ) {
      zones {
        zone
        component {
          data
          id
          template
          name
        }
        impressionCount
        clickRef
      }
    }
  }
`;

export const IMPRESSION_PROGRAMMABLE_COMPONENT = gql`
  mutation ProgrammableComponentImpression($impressionHash: String!) {
    programmableComponentImpression(impressionHash: $impressionHash)
  }
`;

export const CLICK_REF_PROGRAMMABLE_COMPONENT = gql`
  mutation ProgrammableComponentClick($clickRefHash: String!) {
    programmableComponentClick(clickRefHash: $clickRefHash)
  }
`;

export const useProgrammableComponent = () => {
  const client = useApolloClient();
  const fetch = useCallback(
    async (variables: GetProgrammableComponentVariables): Promise<GetProgrammableComponent> => {
      const { data } = await client.query({
        query: GET_PROGRAMMABLE_COMPONENT_QUERY_BY,
        variables,
      });

      return data;
    },
    [client],
  );

  const countImpression = useCallback(
    (impressionHash: string): Promise<FetchResult<ProgrammableComponentImpression>> => {
      return client.mutate({
        mutation: IMPRESSION_PROGRAMMABLE_COMPONENT,
        variables: {
          impressionHash,
        },
      });
    },
    [client],
  );

  const countClickRef = useCallback(
    (clickRefHash: string): Promise<FetchResult<ProgrammableComponentClickVariables>> => {
      return client.mutate({
        mutation: CLICK_REF_PROGRAMMABLE_COMPONENT,
        variables: {
          clickRefHash,
        },
      });
    },
    [client],
  );

  return useMemo(() => ({ fetch, countImpression, countClickRef }), [fetch, countImpression, countClickRef]);
};
