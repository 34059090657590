import { ApolloClient, gql, NormalizedCacheObject, useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import { GENERIC_ITEM } from './fragment';
import type {
  GetGenericItemByKeyword,
  GetGenericItemByKeywordVariables,
} from '../__generated__/GetGenericItemByKeyword';
import { GetItemsByKeywordVariables } from '../__generated__/GetItemsByKeyword';
import { UpdateQuery } from './types';

export const GET_GENERIC_ITEM_BY_KEYWORD = gql`
  ${GENERIC_ITEM}
  query GetGenericItemByKeyword($keyword: String!, $from: Int, $size: Int) {
    genericItemByKeyword(keyword: $keyword, from: $from, size: $size) {
      total
      genericItems {
        ...GenericItem
        layoutName
        officialReleasedAt
        approximateReleasedAt
      }
    }
  }
`;

export const fetchGenericItemByKeyword = (
  client: ApolloClient<NormalizedCacheObject>,
  queryParam: GetGenericItemByKeywordVariables,
) => {
  return client.query<GetGenericItemByKeyword, GetItemsByKeywordVariables>({
    query: GET_GENERIC_ITEM_BY_KEYWORD,
    variables: queryParam,
  });
};

export const useGetGenericItemByKeyword = (
  options?: QueryHookOptions<GetGenericItemByKeyword, GetGenericItemByKeywordVariables>,
) => {
  if (options.variables.keyword) {
    options.variables.keyword = options.variables.keyword.trim();
  }
  if (!options.variables.keyword || options.variables.keyword.length < 2) {
    options.skip = true;
  }

  const { loading, error, data, fetchMore } = useQuery<GetGenericItemByKeyword, GetGenericItemByKeywordVariables>(
    GET_GENERIC_ITEM_BY_KEYWORD,
    options,
  );

  const fetchMoreData = useCallback(
    async (variables: GetGenericItemByKeywordVariables) => {
      const updateQuery: UpdateQuery<GetGenericItemByKeyword> = (previousResult, { fetchMoreResult }) => {
        const genericItemByKeyword = {
          ...previousResult.genericItemByKeyword,
          genericItems: [
            ...previousResult.genericItemByKeyword.genericItems,
            ...fetchMoreResult.genericItemByKeyword.genericItems,
          ],
        };

        return { genericItemByKeyword };
      };

      await fetchMore({ updateQuery, variables });
    },
    [fetchMore],
  );

  return {
    loading: options.skip ? false : loading,
    result: data?.genericItemByKeyword,
    error,
    fetchMoreData,
  };
};
