import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const SEND_MAIL = gql`
  mutation sendMail($userToken: String!, $subject: String!, $from: String!, $message: String!, $reason: String!) {
    sendMail(userToken: $userToken, subject: $subject, from: $from, message: $message, reason: $reason) {
      message
      id
    }
  }
`;

export const sendMail = (
  client: ApolloClient<NormalizedCacheObject>,
  userToken: string,
  subject: string,
  from: string,
  message: string,
  reason: string,
) => {
  return client.mutate<any>({
    mutation: SEND_MAIL,
    variables: { userToken, subject, from, message, reason },
  });
};
