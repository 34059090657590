import { ApolloClient, gql, NormalizedCacheObject, QueryHookOptions, useQuery } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { GetPageInfo_pageInfo, GetPageInfoVariables } from '../__generated__/GetPageInfo';
import { ITEM_FRAGMENT } from './fragment';

const SECTION_FRAGMENT = gql`
  fragment Section on PageInfo {
    section {
      id
      title
      url
      imageId
      mainParent
      parents
      children
    }
  }
`;

export const GET_PAGE_INFO_QUERY = gql`
  ${SECTION_FRAGMENT}
  ${ITEM_FRAGMENT}
  query GetPageInfo($url: String!) {
    pageInfo(url: $url) {
      id
      title
      pageNumber
      url
      layout
      pageType
      data
      dataLayout
      tagSlugs
      metaSeo {
        title
        titleH1
        description
        canonical
        robots
      }
      microData
      breadcrumb {
        id
        url
        title
      }
      ...Section
      ...Item
    }
  }
`;

export const GET_PAGE_INFO_RESUME_QUERY = gql`
  query GetPageInfoResume($url: String!) {
    pageInfo(url: $url) {
      id
      title
      pageNumber
      url
      pageType
      metaSeo {
        title
        description
        canonical
        robots
      }
    }
  }
`;

interface GetPageInfo {
  pageInfo: (GetPageInfo_pageInfo & { data: object | string }) | null;
}

export const fetchPageInfo = async (
  client: ApolloClient<NormalizedCacheObject>,
  queryParam: GetPageInfoVariables,
  resume: boolean = false,
): Promise<ApolloQueryResult<GetPageInfo>> => {
  return client.query<GetPageInfo, GetPageInfoVariables>({
    query: resume ? GET_PAGE_INFO_RESUME_QUERY : GET_PAGE_INFO_QUERY,
    variables: queryParam,
  });
};

export const usePageInfo = (options?: QueryHookOptions<GetPageInfo, GetPageInfoVariables>) => {
  const { loading, error, data } = useQuery<GetPageInfo, GetPageInfoVariables>(GET_PAGE_INFO_QUERY, options);
  const pageInfo = data?.pageInfo;

  return { loading, error, pageInfo };
};
